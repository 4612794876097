<template>
  <div>
    <WidgetContainer :style="{ height: widgetContainerHeight }">
      <v-toolbar
        v-if="widget.title"
        ref="toolbar"
        class="toolbar3"
        dense
        flat
        :height="toolbarHeight"
        short
      >
        <v-toolbar-title
          :style="{ fontSize: '1.125rem' }"
          v-text="$t(widget.title)"
        />
      </v-toolbar>

      <WidgetFactory
        :key="JSON.stringify(widget)"
        :props-data="{
          container,
          style: { height: widgetHeight && `${widgetHeight}px` },
        }"
        :widget="widget"
        v-on="$listeners"
      />
    </WidgetContainer>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { DataTableWidgetModel } from '@/tt-widget-components'
import { Resources } from '@/tt-entity-design/src/types'
import {
  WidgetContainerInterface,
  WidgetContainerType,
} from '@/tt-widget-factory'
import { WidgetName } from '@/tt-widget-components/lib/names'

const TOOLBAR_HEIGHT = 60

export default Vue.extend({
  name: 'AssignJobTypesWidget',
  props: {
    height: { type: Number, default: undefined },
    clientId: { type: Number, required: true },
  },
  computed: {
    resourceName() {
      return Resources.TASK_SITE_INSTRUCTIONS
    },
    container(): WidgetContainerInterface {
      return {
        height: this.widgetHeight,
        hideTitle: true,
        type: WidgetContainerType.STANDALONE,
      }
    },
    columns(): ColumnInputDefinition[] | undefined {
      return [
        {
          headerText: 'assign-job-type.columns.description',
          attributeName: 'taskType.name',
        },
        {
          headerText: 'assign-job-type.columns.details',
          attributeName: 'jobInstructions',
        },
        {
          headerText: 'vendors.tabs.assign-job-type.column.dispatchTask',
          attributeName: 'taskType.dispatchable',
        },
        {
          headerText: 'vendors.tabs.assign-job-type.column.runsheetTasks',
          attributeName: 'taskType.schedulableRunsheet',
        },
        {
          headerText: 'vendors.tabs.assign-job-type.column.siteTask',
          attributeName: 'taskType.schedulableSiteTask',
        },
        {
          attributeName: 'extensions.periodicVisits.weekly',
        },
        {
          headerText: 'assign-job-type.columns.vendor',
          attributeName: 'vendor',
          component: {
            is: 'AssignedJobTypeVendorColumn',
          },
        },
      ]
    },
    toolbarHeight(): number {
      return this.widget.title ? TOOLBAR_HEIGHT : 0
    },
    widget(): DataTableWidgetModel | null {
      return {
        title: '',
        is: WidgetName.DATA_TABLE_WIDGET,
        component: {
          columns: this.columns,
        },
        query: {
          resource: this.resourceName,
          filters: [
            {
              attribute: 'site',
              operator: FilterOperatorType.EQUAL,
              value: this.clientId,
            },
          ],
        },
        allowActions: true,
        toolbar: {
          filterOptions: {
            allowSearch: true,
            filters: [],
          },
          displayCounts: true,
        },
      }
    },
    widgetContainerHeight(): string | undefined {
      return this.height && `${this.height}px`
    },
    widgetHeight(): number {
      return this.height && this.height - this.toolbarHeight
    },
  },
})
</script>
