<template>
  <div class="assign-job-types fill-height">
    <v-toolbar
      class="toolbar3"
      dense
      flat
      :height="toolbarHeight"
      short
      :style="{ borderBottom: '1px solid #E1E4E6 !important' }"
    >
      <v-toolbar-title v-text="$t(pageTitle)" />
      <v-spacer />
      <v-btn
        class="text-none"
        color="ttPrimary"
        :depressed="true"
        dark
        :fab="$vuetify.breakpoint.smAndDown"
        :small="$vuetify.breakpoint.smAndDown"
        @click.stop="openForm"
      >
        <v-icon> add </v-icon>
        <span
          v-if="$vuetify.breakpoint.mdAndUp"
          v-text="$t(textTranslationKey)"
        />
      </v-btn>
    </v-toolbar>

    <AssignJobTypesWidget :client-id="clientId" :height="widgetsHeight" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { VueConstructor } from 'vue/types/umd'

import { FieldErrorRule } from '@tracktik/tt-json-schema-form'

import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { getCreateFormName } from '@/tt-widget-entity-flow/intents/helpers'
import { Resources } from '@/tt-entity-design/src/types'

import AssignJobTypesWidget from './AssignJobTypesWidget.vue'

export default (Vue as VueConstructor<Vue>).extend({
  name: 'AssignJobTypes',
  components: {
    AssignJobTypesWidget,
  },
  props: {
    clientId: { type: Number, required: true },
    height: { type: Number, default: 800 },
    pageTitle: { type: String, required: true },
  },
  computed: {
    toolbarHeight(): number {
      return 80
    },
    widgetsHeight(): number {
      return this.height - this.toolbarHeight
    },
    textTranslationKey(): string {
      return 'vendors.tabs.assign-job-types.add-job-type-btn'
    },
    createFormDefinitionName(): string {
      return getCreateFormName(Resources.TASK_SITE_INSTRUCTIONS)
    },
  },
  methods: {
    async openForm() {
      const itemHook = new EntityItemHook(this.$appContext, {
        entityId: 0,
        entity: { id: 0 },
        resourceName: Resources.TASK_SITE_INSTRUCTIONS,
      })

      const skippedAttributes = ['site']
      const formConfig = {
        resourceName: Resources.TASK_SITE_INSTRUCTIONS,
        title: 'vendors.tabs.assign-job-types.add-job-type-modal-title',
        initialModel: {
          site: this.clientId,
        },
        formOptions: {
          fieldErrorRule: FieldErrorRule.ONLY_TOUCHED,
          requiredFieldLabelTransformer: null,
          definitions: {
            [this.createFormDefinitionName]: {
              skip: skippedAttributes,
            },
          },
        },
        itemHook,
      }

      this.$eventManager.dispatchEvent(EntityIntentTypes.CREATE, {
        ...formConfig,
      })
    },
  },
})
</script>
